<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375.53 412.49"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M337.87,412.49c-1.08-50.6-21.94-91.09-61.92-121.12-26.34-19.79-56.68-29.3-89.75-28.77-40.86.67-75.94,15.53-104.79,44.57-28.7,28.9-42.94,64-43.71,105H0c3.41-80.21,42.33-136.47,113.9-171.94-41.64-31.25-61.84-72.41-56.36-124.61C61.36,79.27,78.82,50,107.27,27.5,158.18-12.68,237-9.86,284.83,42.8a130.21,130.21,0,0,1,33.33,103.79,127.56,127.56,0,0,1-18.38,52.88C289.92,215.63,277,228.8,261.33,240,333,275.4,372,331.67,375.53,412.49H337.87ZM94.11,131.12a93.73,93.73,0,0,0,93.49,94c51.42.31,93.65-41.87,93.8-93.66a93.75,93.75,0,0,0-93.49-94C136.47,37.15,94.25,79.31,94.11,131.12Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconSingleDonor',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
